import React, { useState } from "react";
import ReactPaginate from "react-paginate";

type PaginationProps = {
    itemsPerPage: any;
    totalCount: any;
    pageIndexCallback: Function;
    pageSelectionCallback: Function;
  };

export default function PaginatedItems(props: PaginationProps) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState<any | number> (0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)

  let endOffset = parseInt(itemOffset) + parseInt(props.itemsPerPage);
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const pageCount = Math.ceil(props.totalCount / props.itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * props.itemsPerPage) % props.totalCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );

    props.pageIndexCallback(event.selected);
    setItemOffset(newOffset);
  };

  const HandleChange = (value:any) => {
    props.pageSelectionCallback(value);
  };

  return (
    <>
      <div className="admin-pagination">
        <div className="admin-pages-showing">
          {" "}
          <span>Showing : {parseInt(itemOffset) + 1} to </span>
          {parseInt(itemOffset) + parseInt(props.itemsPerPage) > props.totalCount && (
            <span> {parseInt(props.totalCount)}</span>
          )}{" "}
          {parseInt(itemOffset) + parseInt(props.itemsPerPage) <= props.totalCount && (
            <span> {parseInt(itemOffset) + parseInt(props.itemsPerPage)}</span>
          )}{" "}
          of {props.totalCount}{" "}
          <select
            value={props.itemsPerPage}
            onChange={(e) => HandleChange(e.target.value)}
          >
            <option value="3">3</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>

        <ReactPaginate
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        //   renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}
