import React from "react";


type ColumnVisibilityProps = {
  columnVisibilityViewObject: any;
  addNewRecord: Function;
  showHideVisibilityList: Function;
  showHideChangeHandler: Function;
};

function ColumnVisibilityView(props: ColumnVisibilityProps) {
  return (
    <div className="admin-add">
    <button
      className="btn btn-sm btn-primary"
      onClick={() => props.addNewRecord()}
    >
      <img
        src="images/plus.png"
        width="16px"
        height="15px"
        alt="Edit"
      />
    </button>
    <button
      className="btn btn-primary"
      onClick={() => props.showHideVisibilityList()}
    >
      <img
        src="images/eye.png"
        width="19px"
        height="13px"
        alt="Edit"
      />
    </button>
    <div className="admin-show-hide">
      {props.columnVisibilityViewObject.ShowHide && (
        <ul>
          {props.columnVisibilityViewObject.ColumnNames.map(
            (ColumnNames: any, index: any) => {
              if (ColumnNames.ActualColumnName !== "HasFK") {
                return (
                  <li key={index}>
                    <div className="input--radio check--btn">
                      <input
                        className="check-form"
                        type="checkbox"
                        name={ColumnNames.ActualColumnName}
                        value={ColumnNames.IsVisible}
                        checked={ColumnNames.IsVisible}
                        onChange={(e)=>props.showHideChangeHandler(e)}
                      />
                      <span className="check--mark"></span>
                    </div>
                    <span>{ColumnNames.Name} </span>
                  </li>
                );
              }
            }
          )}
        </ul>
      )}
    </div>
  </div>
  )
}

export default ColumnVisibilityView
