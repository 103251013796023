import axios from "axios";
import AppContext from "../Context/Context";

 const axiosAuth = axios.create()

//we intercept every requests 
 axiosAuth.interceptors.request.use(async function(config){
    //anything you want to attach to the requests such as token 
    var token = AppContext.GetCookie("Token");
    if (token && token !== "" && !AppContext.IsJwtExpired(token)) {
        config.headers.Authorization = "Bearer " + token;
      } else {
        await AppContext.GetNewJWTTokenFromRefreshToken(true);
        token = AppContext.GetCookie("Token");
        config.headers.Authorization = "Bearer " + token;
      }
    return config;
}, error => {
 
    return Promise.reject(error)
})



//we intercept every response
axiosAuth.interceptors.response.use(async function(response){

    return response;
}, error => {
//check for authentication or anything like that
if(error.response.status === 401){
    //AppContext.SetCookie('Token','',0)
   // window.location = 'https://authstg.manyge.com/login'
}
    return Promise.reject(error)
})

export default axiosAuth 

