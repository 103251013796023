import moment from "moment";

type GridViewProps = {
  gridViewObject: any;
  editRecord: Function;
  confirmDeleteModal: Function;
};

function GridView(props: GridViewProps) {
  return (
    <table className="table table-dark table-striped">
      <thead>
        <tr>
          {props.gridViewObject.ColumnNames.map(
            (ColumnNames: any, index: any) => {
              if (ColumnNames.IsVisible) {
                return (
                  <th key={index}>
                    {ColumnNames.Name}
                  </th>
                );
              }
            }
          )}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {props.gridViewObject.GridData[0] &&
          props.gridViewObject.GridData[0].map((GridData: any, index: any) => (
            <tr key={index}>
              {props.gridViewObject.ColumnNames.map(
                (ColumnNames: any, c_index: any) => {
                  if (
                    ColumnNames.DataType !== "boolean" &&
                    ColumnNames.DataType !== "datetime" &&
                    ColumnNames.IsVisible
                  ) {
                    return (
                      <td key={c_index}>
                        {GridData[ColumnNames.ActualColumnName]}
                      </td>
                    );
                  }
                  if (
                    ColumnNames.DataType === "boolean" &&
                    ColumnNames.IsVisible
                  ) {
                    return (
                      <td key={c_index}>
                        {GridData[ColumnNames.ActualColumnName] === true
                          ? "true"
                          : "false"}
                      </td>
                    );
                  }
                  if (
                    ColumnNames.DataType === "datetime" &&
                    ColumnNames.IsVisible
                  ) {
                    return (
                      <td key={c_index}>
                        {moment(GridData[ColumnNames.ActualColumnName]).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    );
                  }
                }
              )}
              <td>
                <button className="btn btn-sm btn-primary"
                onClick={() =>
                  props.editRecord(GridData.MGBlogArticleID)
                }
                >
                  <img
                    src="images/edit.png"
                    width="18px"
                    height="18px"
                    alt="Edit"
                  />
                </button>
                <button className="btn btn-sm btn-danger"
                 onClick={() =>
                  props.confirmDeleteModal(GridData.MGBlogArticleID)
                }
                >
                  <img
                    src="images/delete.png"
                    width="18px"
                    height="18px"
                    alt="Edit"
                  />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

//export default GridView;
export default GridView;
