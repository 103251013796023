import { Routes, Route } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import React, { useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import MGBlogArticle from "./components/Pages/MGBlogArticlePage";
import Header from "./components/App/Header";
function App() {
  return (
    <main>
      <NotificationContainer />
      <div className="App--main">
        <Header />
        <div className="Body--Main">
          <Routes>
            <Route path="" element={<MGBlogArticle />} />
            <Route path="mgblogarticle" element={<MGBlogArticle />} />
          </Routes>
        </div>
      </div>
    </main>
  );
}

export default App;
