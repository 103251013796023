import { Link } from "react-router-dom";
import React from "react";
export default function Header() {

  return (
    <>
      <header className="header">
        <div className="header--start">
          <figure className="header-start__logo">
            <img
              src="/images/logo.png"
              alt="logo"
              width="84px"
              height="20px"
            />
          </figure>
        </div>
        <div className="header--end">
          <div className="header--end__user">
            <a className="user--img">
              {/* <img
                src="/images/user.png"
                alt="user"
                width="30px"
                height="30px"
              /> */}
              <span>AP</span>
            </a>
          </div>
        </div>
      </header>
      <menu className="menu">
        <div className="menu--link">
          <Link 
        to={"/"}>
            <img
              src="/images/view.png"
              alt="Dashboard"
              width="18px"
              height="16px"
            />
          </Link>
        </div>
      </menu>
    </>
  );
}
