import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

type SearchProps = {
  searchViewObject: any;
  searchSubmitHandeler: Function;
  changeHandler: Function;
  clearFilter: Function;
};
function SearchView(props: SearchProps) {
  return (
    <div className="admin-search">
      <h3>Search</h3>

      <form>
        <div className="row">
          {props.searchViewObject.ColumnNames.map(
            (ColumnNames: any, index: any) => {
              if (ColumnNames.DataType === "FK" && ColumnNames.IsSearchable) {
                return (
                  <>
                    {ColumnNames.FKColumnName.map(
                      (FKColumnNames: any, FKindex: any) => (
                        <div key={FKindex} className="col-sm-4">
                          <span>{FKColumnNames.Name}</span>
                          <select
                            className="form-control"
                            // defaultValue={
                            //   this.state[FKColumnNames.ActualColumnName]
                            // }
                            onChange={(e) => props.changeHandler(e)}
                          >
                            <option value="">Please Select</option>
                            {props.searchViewObject.ForeignKeysData[0] &&
                              props.searchViewObject.ForeignKeysData[0][
                                FKindex
                              ] &&
                              props.searchViewObject.ForeignKeysData[0][
                                FKindex
                              ].map((FKItem: any, FKItemIndex: any) => (
                                <option key={FKItem.PID} value={FKItem.PID}>
                                  {FKItem.Name}
                                </option>
                              ))}
                          </select>
                        </div>
                      )
                    )}
                  </>
                );
              }

              if (ColumnNames.DataType === "int" && ColumnNames.IsSearchable) {
                return (
                  <div key={index} className="col-sm-4">
                    <span>{ColumnNames.Name}: </span>
                    <input
                      className="form-control"
                      type="number"
                      name={ColumnNames.ActualColumnName}
                      value={props.searchViewObject.ColumnNames.ActualColumnName}
                      onChange={(e) => props.changeHandler(e)}
                    />
                  </div>
                );
              }
              if (
                (ColumnNames.DataType === "nvarchar" ||
                  ColumnNames.DataType === "nvarcharMax") &&
                ColumnNames.IsSearchable
              ) {
                return (
                  <div key={index} className="col-sm-4">
                    <span>{ColumnNames.Name}: </span>
                    <input
                      className="form-control"
                      type="text"
                      name={ColumnNames.ActualColumnName}
                      value={props.searchViewObject.ColumnNames.ActualColumnName}
                      onChange={(e) => props.changeHandler(e)}
                    />
                  </div>
                );
              }
              if (
                ColumnNames.DataType === "datetime" &&
                ColumnNames.IsSearchable
              ) {
                return (
                  <div key={index} className="col-sm-4">
                    <span>{ColumnNames.Name}: </span>
                    <input
                      className="form-control"
                      type="date"
                      name={ColumnNames.ActualColumnName}
                      value={props.searchViewObject.ColumnNames.ActualColumnName}
                      onChange={(e) => props.changeHandler(e)}
                    />
                  </div>
                );
              }
              if (
                ColumnNames.DataType === "boolean" &&
                ColumnNames.IsSearchable
              ) {
                return (
                  <div key={index} className="col-sm-4">
                    <span>{ColumnNames.Name}: </span>
                    <div className="input--radio check--btn">
                      <input
                        className="check-form"
                        type="checkbox"
                        name={ColumnNames.ActualColumnName}
                        value={
                          props.searchViewObject.ColumnNames.ActualColumnName
                        }
                        checked={
                          props.searchViewObject.ColumnNames.ActualColumnName
                        }
                        onChange={(e) => props.changeHandler(e)}
                      />
                      <span className="check--mark"></span>
                    </div>
                  </div>
                );
              }
            }
          )}
          <div className="action-buttons">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => props.searchSubmitHandeler(e, true, true)}
            >
              Search
            </button>
            <button
              onClick={(e) => props.clearFilter(e)}
              className="btn btn-secondary"
            >
              Clear Filter
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SearchView;
