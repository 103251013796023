import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import GridView from "../AdminControls/GridView/GridView";
import AddUpdateForm from "../AdminControls/AddUpdateForm/AddUpdateForm";
import SearchView from "../AdminControls/SearchView/SearchView";
import ColumnVisibilityView from "../AdminControls/ColumnVisibilityView/ColumnVisibilityView";
import PaginatedItems from "../AdminControls/Pagination/Pagination";
let MGBlogColumnNames = [
  {
    Name: "HasFK",
    ActualColumnName: "HasFK",
    OrderBy: 0,
    DataType: "FK",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
    FKColumnName: [
      { Name: "Blog Category", ActualColumnName: "MGBlogCategoryID" },
    ],
  },
  {
    Name: "Blog Article ID",
    ActualColumnName: "MGBlogArticleID",
    OrderBy: 1,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Blog Category ID",
    ActualColumnName: "MGBlogCategoryID",
    OrderBy: 2,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Blog Category",
    ActualColumnName: "MGBlogCategoryName",
    OrderBy: 2,
    DataType: "int",
    IsVisible: true,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Title",
    ActualColumnName: "Title",
    OrderBy: 3,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: true,
  },
  {
    Name: "Minute Read",
    ActualColumnName: "MinuteRead",
    OrderBy: 4,
    DataType: "int",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Article Date",
    ActualColumnName: "ArticleDate",
    OrderBy: 5,
    DataType: "datetime",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Short Article",
    ActualColumnName: "ShortArticle",
    OrderBy: 6,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: true,
  },
  {
    Name: "Full Article",
    ActualColumnName: "FullArticle",
    OrderBy: 7,
    DataType: "nvarcharMax",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: true,
  },
  {
    Name: "Thumbnail URL",
    ActualColumnName: "ThumbnailImageURL",
    OrderBy: 8,
    DataType: "nvarcharMax",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Image URL",
    ActualColumnName: "ArticleImageURL",
    OrderBy: 9,
    DataType: "nvarcharMax",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Featured Article",
    ActualColumnName: "FeaturedArticle",
    OrderBy: 10,
    DataType: "boolean",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Is Active",
    ActualColumnName: "IsActive",
    OrderBy: 11,
    DataType: "boolean",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Order By",
    ActualColumnName: "PTOrderBy",
    OrderBy: 12,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Meta Title",
    ActualColumnName: "MetaTitle",
    OrderBy: 13,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Meta Description",
    ActualColumnName: "MetaDescription",
    OrderBy: 14,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Meta Keywords",
    ActualColumnName: "MetaKeywords",
    OrderBy: 15,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Thumbnail Image Alt",
    ActualColumnName: "ThumbnailImageAlt",
    OrderBy: 16,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Thumbnail Image Title",
    ActualColumnName: "ThumbnailImageTitle",
    OrderBy: 17,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Article Image Alt",
    ActualColumnName: "ArticleImageAlt",
    OrderBy: 18,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Article Image Title",
    ActualColumnName: "ArticleImageTitle",
    OrderBy: 19,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Page URL",
    ActualColumnName: "PageURL",
    OrderBy: 20,
    DataType: "nvarchar",
    IsVisible: true,
    CanAddEdit: true,
    IsSearchable: false,
  },
  {
    Name: "Extra Text Field 1",
    ActualColumnName: "ExtraTextField1",
    OrderBy: 21,
    DataType: "nvarchar",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Created By ID",
    ActualColumnName: "CreatedByID",
    OrderBy: 1000,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Creation Time stamp",
    ActualColumnName: "CreationTimestamp",
    OrderBy: 1001,
    DataType: "datetime",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Last Updated By ID",
    ActualColumnName: "LastUpdatedByID",
    OrderBy: 1002,
    DataType: "int",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Last Updated Time stamp",
    ActualColumnName: "LastUpdatedTimestamp",
    OrderBy: 1003,
    DataType: "datetime",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
  {
    Name: "Is Deleted",
    ActualColumnName: "IsDeleted",
    OrderBy: 1004,
    DataType: "boolean",
    IsVisible: false,
    CanAddEdit: false,
    IsSearchable: false,
  },
];

function MGBlogArticlePage() {

  const [mgBlogArticleObject, setMGBlogArticleObject] = useState({
    IsAddNew: false,
    IsEdit: false,
    ShowHide: false,
    GridData: [] as any,
    ColumnNames: [] as any,
    ForeignKeysData: [] as any,
    UpdateDOM: false,
    LoadingState: false,
    TotalCount: 0,
    PageIndex: 0,
    RecordsPerPage: 3,
    SortExpressions: "MGBlogArticleID",
    SortDirection: "A",
    AddEditObjects: {},
  }); 

  useEffect(() => {
    MGBlogColumnNames = AppContext.SortJsonData(
      MGBlogColumnNames,
      "OrderBy"
    ) as any;
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      ColumnNames: MGBlogColumnNames,
    }));

    getForeignKeysData();
    getGridData();
  }, []);

  function getForeignKeysData() {
    axiosAuth
      .get(
        AppContext.BlogAPI +
          "api/MGBlogArticle/MGBlogArticleGetCompleteDataSetForForeignKeysApi"
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var foreignKeysData = response.data.Data;

          setMGBlogArticleObject((prevState) => ({
            ...prevState,
            ForeignKeysData: [foreignKeysData],
          }));
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function getGridData(e:any=null) {
    if(e !== null){
      e.preventDefault();
    }
    let object = {} as any;
    object.sortexpressions = "MGBlogArticleID";
    object.sortdirection = "A";
    object.pageindex = 0;
    object.recordsperpage = mgBlogArticleObject.RecordsPerPage;
    axiosAuth
      .post(
        AppContext.BlogAPI +
          "api/MGBlogArticle/MGBlogArticleGetCompleteGridDataApi",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var gridData = response.data.Data[1];
          var totalCount = response.data.Data[0][0].TotalCount;
          let object = {} as any;

          setMGBlogArticleObject((prevState) => ({
            ...prevState,
            GridData: [gridData],
            TotalCount: totalCount,
            AddEditObjects: object,
          }));

        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function showHideVisibilityList() {
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      ShowHide: !mgBlogArticleObject.ShowHide,
    }));
  }

  function addNewRecord() {
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      IsAddNew: true,
      IsEdit: false,
    }));
  }

  function cancelAddEditForm() {
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      IsAddNew: false,
      IsEdit: false,
    }));
  }

  function submitHandeler(e: any) {
    e.preventDefault();

    let object = {} as any;
    let AddEditObject = {} as any;

    object.MGBlogArticleID = null;
    object.MGBlogCategoryID = null;
    object.Title = null;
    object.MinuteRead = null;
    object.ArticleDate = null;
    object.ShortArticle = null;
    object.FullArticle = null;
    object.ThumbnailImageURL = null;
    object.ArticleImageURL = null;
    object.FeaturedArticle = null;
    object.IsActive = null;
    object.PTOrderBy = null;
    object.MetaTitle = null;
    object.MetaDescription = null;
    object.MetaKeywords = null;
    object.ThumbnailImageAlt = null;
    object.ThumbnailImageTitle = null;
    object.ArticleImageAlt = null;
    object.ArticleImageTitle = null;
    object.PageURL = null;
    object.ExtraTextField1 = null;
    object.CreatedByID = null;
    object.CreationTimestamp = null;
    object.LastUpdatedByID = null;
    object.LastUpdatedTimestamp = null;
    object.IsDeleted = null;

    let l_blogArticleObject = mgBlogArticleObject as any;

    for (let field in object) {
      let AddEditField = field.toLowerCase();
      if (
        l_blogArticleObject.AddEditObjects[field] !== undefined &&
        l_blogArticleObject.AddEditObjects[field] != null
      )
        object[field] = l_blogArticleObject.AddEditObjects[field];
      AddEditObject[AddEditField] = l_blogArticleObject.AddEditObjects[field];
    }
    addEditNewRecord(AddEditObject);
  }

  function addEditNewRecord(AddEditObject: any) {
    axiosAuth
      .post(
        AppContext.BlogAPI + "api/MGBlogArticle/MGBlogArticleSaveApi",
        AddEditObject
      )
      .then((response) => {
        if (response.data.Status === 1) {
          AppContext.ShowNotification("success", response.data.Message);
          searchSubmitHandeler(null, false, true);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
        cancelAddEditForm();
      })
      .catch((error) => {
        AppContext.ShowNotification("error", error.message);
        console.error("There was an error!", error);
      });
  }

  function changeHandler(e: any) {
    let l_Type = e.target.type;
    let l_Value = e.target.value;
    if (l_Type === "checkbox") {
      if (e.target.checked) {
        l_Value = true;
      } else {
        l_Value = false;
      }
    }

    if (l_Type === "number" || l_Type === "select-one") {
      l_Value = parseInt(l_Value);
    }

    let AddEditObjects: any = mgBlogArticleObject.AddEditObjects;
    AddEditObjects[e.target.name] = l_Value;
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      AddEditObjects: AddEditObjects,
    }));
  }

  function showHideChangeHandler(e: any) {
    let l_Value = e.target.value;
    let l_Name = e.target.name;
    const { ColumnNames } = mgBlogArticleObject;
    for (let i = 0; i < ColumnNames.length; i++) {
      var l_ColumnName = [ColumnNames[i].ActualColumnName];
      if (l_ColumnName[0] === l_Name) {
        if (e.target.checked) {
          l_Value = true;
        } else {
          l_Value = false;
        }
        ColumnNames[i].IsVisible = l_Value;
        setMGBlogArticleObject((prevState) => ({
          ...prevState,
          ColumnNames: ColumnNames,
        }));
      }
    }
  }

  function searchSubmitHandeler(
    e: any,
    p_boolSearch: any,
    p_boolRunAnimation: any
  ) {
    if (e && e !== null) e.preventDefault();

    let object = {} as any;
    let AddEditObject = {} as any;
    AddEditObject.sortexpressions = mgBlogArticleObject.SortExpressions;
    AddEditObject.sortdirection = mgBlogArticleObject.SortDirection;
    AddEditObject.pageindex = mgBlogArticleObject.PageIndex;
    AddEditObject.recordsperpage = mgBlogArticleObject.RecordsPerPage;
    object.MGBlogArticleID = null;
    object.MGBlogCategoryID = null;
    object.Title = null;
    object.MinuteRead = null;
    object.ArticleDate = null;
    object.ShortArticle = null;
    object.FullArticle = null;
    object.ThumbnailImageURL = null;
    object.ArticleImageURL = null;
    object.FeaturedArticle = null;
    object.IsActive = null;
    object.PTOrderBy = null;
    object.MetaTitle = null;
    object.MetaDescription = null;
    object.MetaKeywords = null;
    object.ThumbnailImageAlt = null;
    object.ThumbnailImageTitle = null;
    object.ArticleImageAlt = null;
    object.ArticleImageTitle = null;
    object.PageURL = null;
    object.ExtraTextField1 = null;
    object.CreatedByID = null;
    object.CreationTimestamp = null;
    object.LastUpdatedByID = null;
    object.LastUpdatedTimestamp = null;
    object.IsDeleted = null;
    let l_blogArticleObject = mgBlogArticleObject as any;

    if (p_boolSearch) {
      for (let field in object) {
        let AddEditField = field.toLowerCase();
        if (
          l_blogArticleObject.AddEditObjects[field] &&
          l_blogArticleObject.AddEditObjects[field] !== undefined &&
          l_blogArticleObject.AddEditObjects[field] !== null &&
          l_blogArticleObject.AddEditObjects[field] !== "" &&
          l_blogArticleObject.AddEditObjects[field] !== 0
        ) {
          object[field] = l_blogArticleObject.AddEditObjects[field];
          AddEditObject[AddEditField] =
            l_blogArticleObject.AddEditObjects[field];
        }
      }
    }
    if (p_boolRunAnimation) {
      //this.setState({ LoadingState: true });
    }
    axiosAuth
      .post(
        AppContext.BlogAPI +
          "api/MGBlogArticle/MGBlogArticleGetCompleteGridDataApi",
        AddEditObject
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var gridData = response.data.Data[1];
          var totalCount = response.data.Data[0][0].TotalCount;

          setMGBlogArticleObject((prevState) => ({
            ...prevState,
            GridData: [gridData],
            TotalCount: totalCount,
          }));
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function clearFilter(e:any) {
    getGridData(e);
  }

  function editRecord(p_id: any) {
    const { ColumnNames, GridData } = mgBlogArticleObject;
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      IsAddNew: false,
      IsEdit: true,
    }));

    let editObject = {} as any;
    for (let i = 0; i < GridData[0].length; i++) {
      if (GridData[0][i].MGBlogArticleID === p_id) {
        for (let j = 0; j < ColumnNames.length; j++) {
          var l_ColumnName = [ColumnNames[j].ActualColumnName];
          var l_ColumnValue = GridData[0][i][ColumnNames[j].ActualColumnName];
          editObject[l_ColumnName[0]] = l_ColumnValue;
        }
      }
    }
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      AddEditObjects: editObject,
    }));
  }

  function confirmDeleteModal(p_id: any) {
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(p_id),
        },
        {
          label: "No",
        },
      ],
    });
  }

  function deleteRecord(p_id: any) {
    const { GridData } = mgBlogArticleObject;
    for (let i = 0; i < GridData[0].length; i++) {
      if (GridData[0][i].MGBlogArticleID === p_id) {
        GridData[0].splice(i, 1);
        break;
      }
    }
    let object = {} as any;
    object.mgblogarticleid = p_id;
    axiosAuth
      .post(
        AppContext.BlogAPI + "api/MGBlogArticle/MGBlogArticleDeleteRecordApi",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          AppContext.ShowNotification("success", response.data.Message);
          searchSubmitHandeler(null, false, true);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
        cancelAddEditForm();
      })
      .catch((error) => {
        AppContext.ShowNotification("error", error.message);
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    // if(blogArticleObject.PageIndex !== 0){
    searchSubmitHandeler(null, true, false);
    // }
  }, [mgBlogArticleObject.PageIndex, mgBlogArticleObject.RecordsPerPage]);

  function HandlePageIndexCallback(PageIndex: any) {
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      PageIndex: PageIndex,
    }));
  }

  function HandlePageSelectionCallback(RecordsPerPage: any) {
    setMGBlogArticleObject((prevState) => ({
      ...prevState,
      RecordsPerPage: RecordsPerPage,
      PageIndex: 0,
    }));
  }

  return (
    <>
      {!mgBlogArticleObject.IsAddNew &&
        !mgBlogArticleObject.IsEdit &&
        mgBlogArticleObject.GridData.length > 0 && (
          <div className="admin-form">
            <div className="admin-table">
              <SearchView
                searchViewObject={mgBlogArticleObject}
                searchSubmitHandeler={searchSubmitHandeler}
                changeHandler={changeHandler}
                clearFilter={clearFilter}
              />
              <ColumnVisibilityView
                columnVisibilityViewObject={mgBlogArticleObject}
                addNewRecord={addNewRecord}
                showHideVisibilityList={showHideVisibilityList}
                showHideChangeHandler={showHideChangeHandler}
              />
              <GridView
                gridViewObject={mgBlogArticleObject}
                editRecord={editRecord}
                confirmDeleteModal={confirmDeleteModal}
              />
            </div>
          </div>
        )}
      {(mgBlogArticleObject.IsAddNew || mgBlogArticleObject.IsEdit) && (
        <AddUpdateForm
        addUpdateFormObject={mgBlogArticleObject}
          submitHandeler={submitHandeler}
          changeHandler={changeHandler}
          cancelAddEditForm={cancelAddEditForm}
        />
      )}

      <span
        className={
          !mgBlogArticleObject.IsAddNew &&
          !mgBlogArticleObject.IsEdit &&
          mgBlogArticleObject.GridData.length > 0
            ? ""
            : "no-display"
        }
      >
        <PaginatedItems
          itemsPerPage={mgBlogArticleObject.RecordsPerPage}
          totalCount={mgBlogArticleObject.TotalCount}
          pageIndexCallback={HandlePageIndexCallback}
          pageSelectionCallback={HandlePageSelectionCallback}
        />
      </span>
    </>
  );
}

export default MGBlogArticlePage;
